<template>
  <div class="col">
    <Modal v-bind:title="modal.header" id="commentModal">
      <div class="modal-body">
        <p>{{ modal.body }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Close</button>
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          v-on:click="deleteComment"
        >
          Delete
        </button>
      </div>
    </Modal>

    <CommentReplyModal
      v-bind:user_is_staff="user_is_staff"
      v-bind:parentComment="replyComment"
      v-bind:force_private="force_private"
      v-bind:force_comment_email="force_comment_email"
      v-on:post-comment="createComment"
    />

    <div class="d-flex justify-content-between p-3">
      <h2 class="display-6">Discussion</h2>
      <div v-if="can_edit">
        <button
          type="button"
          v-if="showSortCommentButton"
          class="btn btn-outline-secondary l-4"
          v-on:click="sorting = true"
        >
          Sort Comments
        </button>
        <button
          type="button"
          v-if="showFinishSortingCommentButton"
          class="btn btn-outline-success l-4"
          v-on:click="finishSorting"
        >
          Finish Sorting
        </button>
        <button
          type="button"
          v-if="can_edit"
          class="btn btn-outline-success l-4"
          data-bs-toggle="modal"
          data-bs-target="#newCommentModal"
        >
          Add Comment
        </button>
      </div>
    </div>

    <CreateComment
      v-if="can_edit"
      v-bind="newComment"
      v-bind:user_is_staff="user_is_staff"
      v-bind:flash_message="flash"
      v-bind:show_checkboxes="!this.formSubmitComment"
      v-bind:force_comment_email="force_comment_email"
      v-bind:force_private="force_private"
      v-bind:formSubmitCommentOptional="formSubmitCommentOptional"
      v-on:post-comment="createComment"
      v-on:modal-close="handleModalClose"
    />

    <draggable
      v-model="localComments"
      v-bind:disabled="!sorting"
      v-on:start="dragging = true"
      v-on:end="dragging = false"
      item-key="id"
    >
      <template #item="{element, index}">
        <div
          class="mx-1 my-3 rounded comment-block"
          v-bind:class="commmentBlockStyle"
          v-if="!loading"
        >
          <CommentThread
            v-bind:comment="element"
            v-bind:sorting="sorting"
            v-bind:indexPath="[index]"
            v-bind:key="element.id"
            v-bind:user_is_staff="user_is_staff"
            v-bind:show_commentable_link="show_commentable_link"
            v-bind:force_private="force_private"
            v-bind:force_comment_email="force_comment_email"
            v-bind:can_edit="can_edit"
            v-on:remove-comment="askForDeleteCommentConfirmation"
            v-on:update-comment="updateComment"
            v-on:reply-to-comment="replyToComment"
          />
        </div>
      </template>
    </draggable>

    <h4
      v-if="localComments.length === 0"
      style="text-align: center; padding: 15px 0;"
      class="bg-light"
    >
      There are no comments yet.
      <br />
      <button
        type="button"
        v-if="can_edit"
        class="btn btn-link"
        data-bs-toggle="modal"
        data-bs-target="#newCommentModal"
      >
        Start a conversation.
      </button>
    </h4>

    <LoadingIcon v-if="loading" />
  </div>
</template>

<script>
import draggable from "vuedraggable";

import CommentReplyModal from "./comment-reply-modal.vue";
import CommentThread from "./comment-thread.vue";
import CreateComment from "./create-comment.vue";

import LoadingIcon from "../shared/icons/loading.vue";
import Modal from "../shared/modal/modal.vue";

// this component is the parent component for all comment related actions.
export default {
  // eslint-disable-line no-unused-vars
  components: {
    CommentReplyModal,
    CommentThread,
    CreateComment,
    draggable,
    Modal,
    LoadingIcon,
  },

  props: {
    // functions to handle data paths
    index: Function,
    create: Function,
    destroy: Function,
    update: Function,
    comments_path: String,
    tab: String,
    can_edit: {
      type: Boolean,
      default: true,
    },
    user_is_staff: {
      type: Boolean,
      default: false,
    },
    flash_message: {
      type: String,
      default: null,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    force_comment_email: {
      type: Boolean,
      default: null,
    },
    force_private: {
      type: Boolean,
      default: null,
    },
    show_commentable_link: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modal: {
        header: "Delete Comment?",
        body: "This will delete the comment and any replies. Are you sure?",
      },
      modifiedComment: {
        id: null,
        // an array of numbers representing an index on a comment
        // eg [0, 0, 0] is the first reply to the first reply to the first comment
        indexPath: [],
      },
      newComment: {
        tab_placement: this.tab || null,
        sort: 0,
        parent_id: null,
      },
      localComments: [],
      replyComment: {},
      loading: false,
      sorting: false,
      dragging: false,
      newCommentModal: null,
      replyCommentModal: null,
      hideCloseButton: false,
      flash: this.flash_message,
      formSubmitComment: false,
      formSubmitCommentForm: null,
      formSubmitCommentInput: null,
      formSubmitCommentOptional: false,
    };
  },

  mounted() {
    this.getComments(this.tab ? { tab_placement: this.tab } : {});
    const message = this.flash;
    this.newCommentModal = new bootstrap.Modal(document.querySelector("#newCommentModal"));
    this.replyCommentModal = new bootstrap.Modal(document.querySelector("#replyCommentModal"));
    if (message) {
      this.newCommentModal.show();
    }
  },

  watch: {
    tab(newValue) {
      this.getComments({ tab_placement: newValue });
      this.newComment.tab_placement = newValue;
    },
  },

  methods: {
    getCommentsRoute() {
      return `${this.comments_path}.json`;
    },
    updateCommentRoute(commentId) {
      return `${this.comments_path}/${commentId}`;
    },
    deleteCommentRoute(commentId) {
      return this.updateCommentRoute(commentId);
    },
    createCommentRoute() {
      return this.getCommentsRoute();
    },
    setNewCommentDefaultSort() {
      this.newComment.sort = this.localComments.length;
    },
    async getComments(data) {
      // only show the timer if the network is slow enough that it has not resolved
      // in less than a second. If the network is really fast, we don't want a loading
      // icon show for only 0.5 seconds
      const loadingTimer = window.setTimeout(() => (this.loading = true), 1000);
      const response = await SFCTA.HTTP.get(this.getCommentsRoute(), data);

      this.localComments = response.comments;
      this.setNewCommentDefaultSort();
      // if the request has resolved before the loading timer has even finished
      // then cancel the loading timer so we can not have a race condition
      // occur where loading is set to tre after the request has resolved
      // and loads forever
      window.clearTimeout(loadingTimer);
      this.loading = false;
    },
    async createComment({ comment, indexPath }) {
      if (comment.comment) {
        const response = await SFCTA.HTTP.post(this.createCommentRoute(), { comment });

        if (this.formSubmitComment) {
          this.handleFormSubmitComment(response);
        } else {
          function recursivelyAddComment(localComments, localIndexPath = []) {
            if (localIndexPath.length <= 1) {
              const newComment = { ...response.comment, scrollTo: true };
              localComments.splice(comment.sort, 0, newComment);
              return localComments;
            }
            const index = localIndexPath.shift();
            localComments[index].replies = recursivelyAddComment(
              localComments[index].replies,
              localIndexPath,
            );
            return localComments;
          }

          this.localComments = recursivelyAddComment(this.localComments, indexPath);
          this.setNewCommentDefaultSort();
        }
      } else if (this.formSubmitComment) {
        this.handleFormSubmitComment();
      }

      this.consumeFlash();
    },
    handleFormSubmitComment(response) {
      if (response) {
        this.formSubmitCommentInput.value = response.comment.id;
      }

      this.formSubmitCommentForm.submit();
    },
    async deleteComment() {
      const response = await SFCTA.HTTP.delete(this.deleteCommentRoute(this.modifiedComment.id));

      function recursivelyRemoveComment(localComments, indexPath) {
        if (indexPath.length <= 1) {
          const index = indexPath[0];
          localComments.splice(index, 1);
          return localComments;
        }
        const index = indexPath.shift();
        localComments[index].replies = recursivelyRemoveComment(
          localComments[index].replies,
          indexPath,
        );
        return localComments;
      }

      this.localComments = recursivelyRemoveComment(
        this.localComments,
        this.modifiedComment.indexPath,
      );
      // since we might have removed a comment in the middle of the sort order,
      // we should update the sort order of all comments
      this.updateAllCommentSortValues();
    },
    async updateComment({ comment, indexPath }) {
      const response = await SFCTA.HTTP.put(this.updateCommentRoute(comment.id), { comment });

      function recursivelyUpdateComment(localComments, localIndexPath) {
        if (localIndexPath.length <= 1) {
          const index = localIndexPath[0];
          localComments[index] = Object.assign(localComments[index], comment);
          return localComments;
        }
        const index = localIndexPath.shift();
        localComments[index].replies = recursivelyUpdateComment(
          localComments[index].replies,
          localIndexPath,
        );
        return localComments;
      }
      this.localComments = recursivelyUpdateComment(this.localComments, indexPath);
    },
    replyToComment({ parentComment }) {
      // show the replyComment modal
      this.replyCommentModal.show();
      this.replyComment = parentComment;
    },
    hydrateModal(header, body) {
      this.modal.header = header;
      this.modal.body = body;
    },
    askForDeleteCommentConfirmation({ commentID, indexPath }) {
      // our comment is a reply
      if (indexPath.length > 1) {
        this.hydrateModal("Delete Reply?", "This will delete the reply. Are you sure?");
        this.modifiedComment = { indexPath, id: commentID };
      } else if (indexPath.length === 1) {
        this.hydrateModal(
          "Delete Comment?",
          "This will delete the comment and any replies associated with the comment. Are you sure?",
        );
        this.modifiedComment = { indexPath, id: commentID };
      } else {
        console.error("Malformed indexPath", indexPath); // eslint-disable-line no-console
      }
    },
    getCommentIndexes(comments) {
      let arr = [];
      comments.forEach((comment, index) => {
        arr = arr.concat([{ id: comment.id, sort: index }]);
        if (comment.replies.length > 0) {
          arr = arr.concat(this.getCommentIndexes(comment.replies));
        }
      });
      return arr;
    },
    async updateAllCommentSortValues() {
      const indexes = this.getCommentIndexes(this.localComments);

      SFCTA.HTTP.put("/comments/update_sort_order", { comments: indexes });
    },
    finishSorting() {
      this.sorting = false;
      this.updateAllCommentSortValues();
    },
    setFlash(message) {
      this.flash = message;
    },
    consumeFlash() {
      this.flash = null;
    },
    handleModalClose() {
      this.consumeFlash();
      this.formSubmitComment = false;
    },
    setupFormSubmitComment(
      targetForm,
      targetInput,
      required = true,
      message = "Write your message below",
    ) {
      this.formSubmitComment = true;
      this.setFlash(message);
      this.formSubmitCommentForm = targetForm;
      this.formSubmitCommentInput = targetInput;
      this.formSubmitCommentOptional = !required;

      this.newCommentModal.show();
    },
  },

  computed: {
    commmentBlockStyle() {
      if (!this.sorting) {
        return null;
      }
      return "comment-block--sorting";
    },
    showSortCommentButton() {
      return (
        this.can_edit &&
        this.user_is_staff &&
        this.sortable &&
        !this.sorting &&
        this.localComments.length > 0
      );
    },
    showFinishSortingCommentButton() {
      return this.can_edit && this.user_is_staff && this.sorting && this.localComments.length > 0;
    },
  },
};
</script>
